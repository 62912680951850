<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <rect
      x=".67"
      y="5.8"
      width="5.12"
      height="7.04"
      rx="1.92"
      ry="1.92"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <rect
      x="7.11"
      y="5.8"
      width="5.12"
      height="4.91"
      rx="1.92"
      ry="1.92"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <circle
      cx="1.46"
      cy="2.78"
      r=".78"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <circle
      cx="7.9"
      cy="2.78"
      r=".78"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="2.24"
      y1="2.78"
      x2="7.11"
      y2="2.78"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="8.68"
      y1="2.78"
      x2="12.23"
      y2="2.78"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
